import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../login/OAuth";
import { FaTrash } from 'react-icons/fa';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Grid,
    CircularProgress,
    Typography,
    Dialog,
    DialogTitle,
    DialogActions,
    Modal,
    Box,
    TextField
} from "@mui/material";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function ListProductTypes() {
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const [modalLoading, setModalLoading] = useState(false);
    const [modalError, setModalError] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);

    const [productTypes, setProductTypes] = useState([]);

    const [formData, setFormData] = useState({ name: '', product_type_id: null });

    const fetchProductTypes = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().get("/products/types");
            setProductTypes(result.data);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
            setLoading(false);
        }
    }, [api]);

    useEffect(() => {
        fetchProductTypes();
    }, []);

    const handleCreateType = () => {
        setModalType('create');
        setFormData({ name: '', product_type_id: null });
        setModalOpen(true);
        setModalError(null);
    };

    const handleEditType = (item) => {
        setModalType('edit');
        setFormData({ name: item.name, product_type_id: item.id });
        setModalOpen(true);
        setModalError(null);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setModalError(null);
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleOpenDeleteDialog = (itemId) => {
        setDeleteItemId(itemId);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleSubmit = async () => {
        setModalLoading(true);
        try {
            let result = null;
            if (modalType === 'create') {
                result = await api().post("/products/types/new", { name: formData.name });
            } else {
                result = await api().patch(`/products/types/update`, formData);
            }
            if (result.status < 300) {
                fetchProductTypes();
                setModalError(null);
                setModalOpen(false);
            } else {
                setModalError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setModalError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setModalLoading(false);
        }
    };

    const handleDeleteItem = async () => {
        try {
            const result = await api().patch(`/products/types/disable`, { product_type_id: deleteItemId });
            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    fetchProductTypes();
                    handleCloseDeleteDialog();
                }
            } else {
                alert(`Error ${result.data.detail}`);
            }
        } catch (error) {
            alert("Error", error);
        }

    };

    const cellStyle = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '75px'
    };

    return (
        <div>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={handleCreateType}>
                                        Crear nuevo tipo
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <br />
                    <TableContainer component={Paper} style={{ maxWidth: '600px', margin: '0 auto' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Nombre</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Acciones</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productTypes.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            {item.name}
                                        </TableCell>
                                        <TableCell sx={cellStyle}>
                                            <Box sx={{ display: 'flex', gap: 3 }}>
                                                <Button
                                                    sx={{
                                                        height: '40px',  
                                                        padding: '8px 16px', 
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',  
                                                        minWidth: '100px', 
                                                        flex: '0 1 auto' 
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleEditType(item)}
                                                >
                                                    Editar
                                                </Button>
                                                <Button
                                                    sx={{
                                                        height: '40px',  
                                                        padding: '8px 16px', 
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center', 
                                                        minWidth: '100px', 
                                                        flex: '0 1 auto' 
                                                    }}
                                                    variant="contained"
                                                    color="error"
                                                    onClick={() => handleOpenDeleteDialog(item.id)}
                                                >
                                                    <FaTrash />
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                                    <DialogTitle>¿Estás seguro de que quieres borrar este tipo?</DialogTitle>
                                    <DialogActions>
                                        <Button onClick={handleCloseDeleteDialog} color="primary">
                                            Volver
                                        </Button>
                                        <Button onClick={handleDeleteItem} color="error">
                                            Eliminar
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
            <br />

            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        {modalType === 'create' ? 'Crear nuevo tipo' : 'Editar tipo'}
                    </Typography>
                    <TextField
                        label="Nombre"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    {modalError && (
                        <Typography variant="body2" color="error" style={{ marginBottom: 10 }}>
                            {modalError}
                        </Typography>
                    )}
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={modalLoading || !formData.name}
                        >
                            {modalLoading ? <CircularProgress size={24} /> : 'Aceptar'}
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default ListProductTypes;
