import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import {
    Button,
    TextField,
    Grid,
    Box,
    Paper,
    Typography,
    CircularProgress,
    Autocomplete,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper as TablePaper,
    IconButton
} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import DeleteIcon from '@mui/icons-material/Delete';

dayjs.extend(utc);

function NewOrder() {
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);

    const [buildings, setBuildings] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState(null);

    const [productForm, setProductForm] = useState([]);
    const [errorPriceMessage, setErrorPriceMessage] = useState(null);
    const [errorQuantityMessage, setErrorQuantityMessage] = useState(null);

    const initialNewProductState = {
        product_name: '',
        price: '',
        quantity: '',
        unit: '',
    };

    const [newProduct, setNewProduct] = useState(initialNewProductState);

    const [orderForm, setOrderForm] = useState({
        provider_id: null,
        building_id: null,
        provider_code: null,
        order_date: dayjs().utc().format('YYYY-MM-DD'),
    });

    const fetchProviders = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().get("/providers/");
            setProviders(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    const fetchBuildings = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().get("/buildings/");
            setBuildings(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    useEffect(() => {
        fetchProviders();
        fetchBuildings();
    }, []);

    const handleDateChange = (newValue) => {
        let offerDate = dayjs(newValue).hour(12).minute(0).second(0);
        offerDate = offerDate.utc().format('YYYY-MM-DD');
        setOrderForm(prevState => ({
            ...prevState,
            order_date: offerDate
        }));
    };

    const handleProviderChange = (event, newValue) => {
        setSelectedProvider(newValue);
        setOrderForm(prevState => ({
            ...prevState,
            provider_id: newValue ? newValue.id : null
        }));
    };
    const handleBuildingChange = (event, newValue) => {
        setSelectedBuilding(newValue);
        setOrderForm(prevState => ({
            ...prevState,
            building_id: newValue ? newValue.id : null
        }));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setOrderForm(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const validateForm = () => {
        if (!orderForm.provider_id) {
            setError("Por favor, añada un proveedor");
            return false;
        }
        else if (!orderForm.order_date) {
            setError("Por favor, añada una fecha");
            return false;
        }
        else if (productForm.length < 1) {
            setError("Por favor, añada un articulo como mínimo");
            return false;
        }
        else{
            setError(null);
            return true;
        }
    };

    const createOrder = async (event) => {
        event.preventDefault();
        const isValid = validateForm();
        if (!isValid) {
            return
        }
        try {
            setLoading(true);
            const result = await api().post('/orders/new', { ...orderForm, products: productForm });

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    alert("Pedido creado correctamente");
                    navigate(`/orders/list`);
                    setError(null);
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };

    const handleNewProductChange = (event) => {
        const { name, value } = event.target;

        const priceRegex = /^\d+(\.\d{1,2})?$/;
        let errorMessage = null;

        if (name === "price" || name === "quantity") {
            const decimalPart = value % 1;
            if (decimalPart !== 0 && decimalPart.toFixed(2) != decimalPart) {
                errorMessage = "El valor debe tener máximo dos decimales, sin letras.";
            }
            
            if (name === "price") {
                setErrorPriceMessage(errorMessage);
            } else if (name === "quantity") {
                setErrorQuantityMessage(errorMessage);
            }
        }

        setNewProduct(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAddProduct = () => {
        if (newProduct.product_name && newProduct.price && newProduct.quantity && newProduct.unit) {
            setProductForm([...productForm, newProduct]);
            setNewProduct(initialNewProductState);  // Reset fields after adding product
        }
        setError(null);
    };

    const handleRemoveProduct = (index) => {
        const newProductForm = productForm.filter((_, i) => i !== index);
        setProductForm(newProductForm);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Paper elevation={3} style={{ padding: 20, width: '80%' }}>
                <Typography component="h2" variant="h5">
                    Nuevo Pedido
                </Typography>
                <br />
                <form onSubmit={createOrder}>
                    <Grid container spacing={3} alignItems="center" wrap="nowrap">
                        <Grid item xs={12} md={3}>
                            <Autocomplete
                                options={providers}
                                getOptionLabel={(option) => option.code?.toString() + ' - ' + option.name?.toString()}
                                value={selectedProvider}
                                onChange={handleProviderChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Proveedor"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Autocomplete
                                options={buildings}
                                getOptionLabel={(option) => option.code?.toString() + ' - ' + option.name?.toString()}
                                value={selectedBuilding}
                                onChange={handleBuildingChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Obra"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                name="provider_code"
                                label="Código del pedido"
                                value={orderForm.provider_code}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="es"
                                localeText={{
                                    cancelButtonLabel: "cancelar",
                                    okButtonLabel: "Ok",
                                    datePickerToolbarTitle: 'Seleccionar',
                                }}>
                                <MobileDatePicker
                                    label="Fecha"
                                    closeOnSelect={true}
                                    value={orderForm.order_date ? dayjs(orderForm.order_date) : null}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <br />
                    <Typography variant="h6">Añadir Producto</Typography>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} md={3}>
                            <TextField
                                name="product_name"
                                label="Nombre del Producto"
                                value={newProduct.product_name}
                                onChange={handleNewProductChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                name="price"
                                label="Precio por Unidad"
                                type="number"
                                value={newProduct.price}
                                onChange={handleNewProductChange}
                                fullWidth
                                error={!!errorPriceMessage}
                                helperText={errorPriceMessage}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                name="quantity"
                                label="Cantidad"
                                type="number"
                                value={newProduct.quantity}
                                onChange={handleNewProductChange}
                                fullWidth
                                error={!!errorQuantityMessage}
                                helperText={errorQuantityMessage}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                                <InputLabel id="unit-label">Unidad</InputLabel>
                                <Select
                                    labelId="unit-label"
                                    name="unit"
                                    value={newProduct.unit}
                                    onChange={handleNewProductChange}
                                    label="Unidad"
                                >
                                    <MenuItem value="uds">UDS</MenuItem>
                                    <MenuItem value="kg">KG</MenuItem>
                                    <MenuItem value="l">L</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddProduct}
                                disabled={!!errorPriceMessage || !!errorQuantityMessage || !newProduct.product_name || !newProduct.price || !newProduct.quantity || !newProduct.unit}
                            >
                                Añadir Producto
                            </Button>
                        </Grid>
                    </Grid>
                    <br />
                    <TableContainer component={TablePaper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Precio por Unidad</TableCell>
                                    <TableCell>Cantidad</TableCell>
                                    <TableCell>Unidad</TableCell>
                                    <TableCell>Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productForm.map((product, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{product.product_name}</TableCell>
                                        <TableCell>{product.price}</TableCell>
                                        <TableCell>{product.quantity}</TableCell>
                                        <TableCell>{product.unit}</TableCell>
                                        <TableCell>
                                            <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveProduct(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mt={2} display="flex" justifyContent="flex-end">
                        <Button type="submit" variant="contained" color="primary" disabled={loading}>
                            {loading ? <CircularProgress size={24} /> : 'Crear Pedido'}
                        </Button>
                    </Box>
                </form>
                {error && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error}
                    </Typography>
                )}
            </Paper>
        </div>
    );
}

export default NewOrder;
