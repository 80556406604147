import React, { useState } from 'react';
import { TextField, Button, Typography, CircularProgress } from '@mui/material';
import { useAuth } from "../login/OAuth";

function EmailResetPass() {
    const [user_email, setEmail] = useState(null);
    const { api } = useAuth();
    const [error, setError] = useState('');
    const [resetSent, setResetSent] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleResetClick = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await api().post(`/users/reset/pass`, { email: user_email });
            if (response.status === 200) {
                setResetSent(true);
            }
        } catch (error) {
            setError(error.response.data.detail);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ maxWidth: 400, margin: 'auto', textAlign: 'center' }}>
            {!resetSent ? (
                <div>
                    <Typography variant="h5" gutterBottom>
                        Restablecer Contraseña
                    </Typography>
                    <TextField
                        label="Correo electrónico"
                        variant="outlined"
                        value={user_email}
                        onChange={handleEmailChange}
                        fullWidth
                        margin="normal"
                    />
                    {error && (
                        <Typography variant="body2" color="error">
                            {error}
                        </Typography>
                    )}
                    <Button
                        variant="contained"
                        onClick={handleResetClick}
                        sx={{ mt: 3, mb: 2, backgroundColor: '#E66136', color: 'white', '&:hover': { backgroundColor: '#913d21' } }}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Restablecer'}
                    </Button>
                </div>
            ) : (
                <Typography variant="body1" gutterBottom>
                    Por favor, siga las instrucciones indicadas en el email.
                </Typography>
            )}
        </div>
    );
}

export default EmailResetPass;
