import React, { useState } from 'react';
import { TextField, Button, Typography, CircularProgress } from '@mui/material';
import { useAuth } from "../login/OAuth"
import { useParams, Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';

function ResetPass() {
    const [passwordForm, setPasswordForm] = useState({
        new_pass: '',
        confirmation_pass: ''
    });
    const [error, setError] = useState('');
    const { token } = useParams();
    const { api } = useAuth();
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);  // Estado de carga

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setPasswordForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        if (passwordForm.new_pass !== passwordForm.confirmation_pass) {
            setError('Las contraseñas no coinciden');
            return;
        }

        setLoading(true);  // Empieza a cargar

        try {
            const response = await api().post(`/users/reset/pass/${token}`, passwordForm);
            if (response.status === 200) {
                setSuccess(true);
            }
        } catch (error) {
            setError(error.response.data.detail);
        } finally {
            setLoading(false);  // Termina de cargar
        }
    };

    return (
        <div style={{ maxWidth: 400, margin: 'auto', textAlign: 'center' }}>
            {!success ? (
                <form onSubmit={handleFormSubmit}>
                    <Typography variant="h5" gutterBottom>
                        Restablecer Contraseña
                    </Typography>
                    <TextField
                        type="password"
                        name="new_pass"
                        label="Nueva contraseña"
                        variant="outlined"
                        value={passwordForm.new_pass}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        type="password"
                        name="confirmation_pass"
                        label="Confirmar contraseña"
                        variant="outlined"
                        value={passwordForm.confirmation_pass}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    {error && (
                        <Typography variant="body2" color="error">
                            {error}
                        </Typography>
                    )}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2, backgroundColor: '#E66136', color: 'white', '&:hover': { backgroundColor: '#913d21' } }}
                        disabled={loading}  // Desactivar cuando esté cargando
                    >
                        {loading ? <CircularProgress size={24} /> : 'Cambiar Contraseña'}
                    </Button>
                </form>
            ) : (
                <div style={{ maxWidth: 400, margin: 'auto', textAlign: 'center', marginTop: 20 }}>
                    <Typography variant="body1" gutterBottom>
                        ¡Contraseña restablecida con éxito!
                    </Typography>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Link to="/sign-in" variant="body2" style={{ textDecoration: 'none', color: '#1976d2' }}>
                                Iniciar sesión
                            </Link>
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    );
}

export default ResetPass;
