import React, { useState } from 'react';
import {
    Box, Typography, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, TextField, Button
} from '@mui/material';
import { Link } from 'react-router-dom';
import Accordion from './Accordion';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import { useAuth } from "../pages/login/OAuth";
import UploadDocument from "./UploadDocument"
import CreatePdf from "./CreatePdf"

dayjs.extend(utc);

const formatDate = (date) => {
    if (!date) {
        return '';
    }
    return dayjs(date).format('DD/MM/YYYY');
};

const InvoiceRetentionTable = ({
    isInvoice,
    isClient,
    showBuilding,
    showClientOrProvider,
    title,
    totalAmount,
    paidAmount,
    remainingAmountSum,
    invoices,
    fetch,
    uploadDocument,
    handleOpenDeleteDialog
}) => {
    const [loading, setLoading] = useState(false);
    const [invoicesAmounts, setInvoicesAmounts] = useState({});
    const [retentionsAmounts, setRetentionsAmounts] = useState({});
    const [invoicesErrors, setInvoicesErrors] = useState({});
    const [retentionsErrors, setRetentionsErrors] = useState({});
    const { api } = useAuth();

    const handlePay = async (invoice_id) => {
        try {
            setLoading(true);
            const endpoint = isInvoice ? 'pay_invoice' : 'pay_retention';
            const amounts = isInvoice ? invoicesAmounts : retentionsAmounts;
            const amountToPay = amounts[invoice_id];

            if (!amountToPay || amountToPay <= 0) {
                alert("Por favor, introduzca un valor válido.");
                return;
            }
            const result = await api().patch(`/invoices/${endpoint}`, { invoice_id: invoice_id, amount: amountToPay });
            if (result.status < 300) {
                alert('Pago realizado correctamente');
                fetch();
            } else {
                alert(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            alert(`Error: ${error.response?.data?.detail || error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleAmountChange = (id, value) => {
        const invoicesList = invoices.filter(item => item.id === id)[0];
        let remainingAmount;
        let newErrors = {};

        if (isInvoice) {
            remainingAmount = invoicesList.total_price - (invoicesList.paid_amount ?? 0) - (invoicesList.retention ?? 0);

            if (value <= 0) {
                newErrors[id] = "El monto debe ser mayor a 0.";
            } else if (value > remainingAmount) {
                newErrors[id] = `El monto no puede exceder el restante (${remainingAmount.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 })}€).`;
            } else {
                delete newErrors[id];
            }

            setInvoicesAmounts(prev => ({
                ...prev,
                [id]: value
            }));
            setInvoicesErrors(newErrors);
        } else {
            remainingAmount = invoicesList.retention - (invoicesList.paid_retention ?? 0);

            if (value <= 0) {
                newErrors[id] = "El monto debe ser mayor a 0.";
            } else if (value > remainingAmount) {
                newErrors[id] = `El monto no puede exceder el restante (${remainingAmount.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 })}€).`;
            } else {
                delete newErrors[id];
            }

            setRetentionsAmounts(prev => ({
                ...prev,
                [id]: value
            }));
            setRetentionsErrors(newErrors);
        }
    };
    const handleDownloadDocument = async (documentId, documentName) => {
        try {
            const response = await api().get(`/documents/${documentId}`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', documentName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            alert(`Error al descargar el documento: ${JSON.stringify(error.response.data.detail)}`);
        }
    };

    // Determine table headers based on isInvoice
    const headers = isInvoice ? [
        { label: 'Código', width: '5%' },
        { label: 'Fecha', width: '5%' },
        ...(showBuilding ? [{ label: 'Obra', width: '8%' }] : []),
        ...(showClientOrProvider ? [{ label: isClient ? 'Cliente' : 'Proveedor', width: '5%' }] : []),
        { label: 'Estado', width: '5%' },
        { label: 'Importe', width: '8%' },
        { label: 'Retención', width: '8%' },
        { label: 'Pagado', width: '8%' },
        { label: 'Restante', width: '6%' },
        { label: 'Cantidad a pagar', width: '8%' },
        { label: 'Acciones', width: '32%' }
    ] : [
        { label: 'Código Factura', width: '5%' },
        { label: 'Fecha Límite', width: '5%' },
        ...(showBuilding ? [{ label: 'Obra', width: '8%' }] : []),
        ...(showClientOrProvider ? [{ label: isClient ? 'Cliente' : 'Proveedor', width: '5%' }] : []),
        { label: 'Estado', width: '5%' },
        { label: 'Importe', width: '8%' },
        { label: 'Pagado', width: '8%' },
        { label: 'Restante', width: '6%' },
        { label: 'Cantidad a pagar', width: '8%' },
        { label: 'Acciones', width: '32%' }
    ];

    return (
        <Accordion title={title}>
            <Box sx={{
                display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
                gap: '16px', padding: '8px 16px', marginBottom: '16px',
                backgroundColor: '#f5f5f5', borderRadius: '8px'
            }}>
                <Typography variant="h8" component="span">
                    <strong>Total Importe:</strong> {totalAmount ? totalAmount.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : '0€'}
                </Typography>
                <Typography variant="h8" component="span">
                    <strong>Total Pagado:</strong> {paidAmount ? paidAmount.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : '0€'}
                </Typography>
                <Typography variant="h8" component="span">
                    <strong>Total Restante:</strong> {remainingAmountSum ? remainingAmountSum.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : '0€'}
                </Typography>
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map((header, index) => (
                                <TableCell key={index} style={{ width: header.width }}>
                                    {header.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices.filter(item => !item.disabled).map((item, index) => {
                            const remainingAmount = isInvoice
                                ? item.total_price - (item.paid_amount ?? 0) - (item.retention ?? 0)
                                : item.retention - (item.paid_retention ?? 0);
                            return (
                                <TableRow key={index}>
                                    <TableCell>{item.code}</TableCell>
                                    <TableCell>{formatDate(isInvoice ? item.invoice_date : item.retention_date)}</TableCell>
                                    {showBuilding && (
                                        <TableCell>
                                            <Link to={`/buildings/update/${item.building.id}`}>
                                                {item.building.code}
                                            </Link>
                                        </TableCell>
                                    )}
                                    {showClientOrProvider && (
                                        <TableCell>
                                            <Link to={`/${isClient ? 'clients' : 'providers'}/update/${item[isClient ? 'client' : 'provider'].id}`}>
                                                {item[isClient ? 'client' : 'provider'].code}
                                            </Link>
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        {isInvoice
                                            ? item.invoice_status === 'paid' ? 'PAGADA' :
                                                item.invoice_status === 'not_paid' ? 'NO PAGADA' :
                                                    item.invoice_status
                                            : item.retention_status === 'paid' ? 'PAGADA' :
                                                item.retention_status === 'not_paid' ? 'NO PAGADA' :
                                                    item.retention_status}
                                    </TableCell>
                                    <TableCell>
                                        {isInvoice
                                            ? item.total_price?.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€'
                                            : item.retention?.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€'
                                        }
                                    </TableCell>
                                    {isInvoice && (
                                        <TableCell>
                                            {(item.retention ?? 0).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€'}
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        {isInvoice
                                            ? item.paid_amount?.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€'
                                            : item.paid_retention?.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€'
                                        }
                                    </TableCell>
                                    <TableCell>{remainingAmount.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€'}</TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={isInvoice ? invoicesAmounts[item.id] || '' : retentionsAmounts[item.id] || ''}
                                            onChange={(e) => handleAmountChange(item.id, parseFloat(e.target.value))}
                                            fullWidth
                                            error={!!(isInvoice ? invoicesErrors[item.id] : retentionsErrors[item.id])}
                                            helperText={isInvoice ? invoicesErrors[item.id] : retentionsErrors[item.id]}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handlePay(item.id)}
                                            sx={{ marginRight: '8px' }}
                                            disabled={remainingAmount <= 0 || !!(isInvoice ? invoicesErrors[item.id] : retentionsErrors[item.id]) || !(isInvoice ? invoicesAmounts[item.id] : retentionsAmounts[item.id]) || loading}
                                        >
                                            {loading ? 'Cargando...' : 'Pagar'}
                                        </Button>
                                        {item.documents && item.documents.length > 0 && (() => {
                                            const activeDocument = item.documents.find(doc => !doc.disabled);
                                            return activeDocument ? (
                                                <Button
                                                    onClick={() =>
                                                        handleDownloadDocument(activeDocument.id, activeDocument.name)
                                                    }
                                                    sx={{ marginRight: 2 }}
                                                >
                                                    {activeDocument.name}
                                                </Button>
                                            ) : null;
                                        })()}
                                        {uploadDocument ? (
                                            <UploadDocument
                                                to={'invoice_id'}
                                                id={item.id}
                                                fetch={fetch}
                                            >
                                            </UploadDocument>
                                        ) : (isInvoice && isClient) ? (
                                            <>
                                                <CreatePdf
                                                    endpoint={`/invoices/${item.id}/generate-pdf/proform`}
                                                    text="Ver proforma"
                                                />
                                                <CreatePdf
                                                    endpoint={`/invoices/${item.id}/generate-pdf/invoice`}
                                                />
                                            </>
                                        ) : null}
                                        {isInvoice && (
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleOpenDeleteDialog(item.id, '/invoices/disable')}
                                            >
                                                Borrar
                                            </Button>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Accordion>
    );
};

export default InvoiceRetentionTable;
