import React, { useState, useEffect, useCallback } from "react";
import Paginate from '../../components/Paginate';
import { Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import dayjs from 'dayjs';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormControl,
    InputLabel,
    Autocomplete,
    MenuItem,
    Select,
    TextField,
    Button,
    Grid,
    CircularProgress,
    Typography,
    Dialog,
    Card,
    CardContent,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";

function ListProviderInvoices() {
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fetchReset, setFetchReset] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(null);

    const [invoices, setInvoices] = useState([]);
    const [amounts, setAmounts] = useState({}); // To store amounts to pay for each invoice
    const [errors, setErrors] = useState({}); // To store errors for each invoice

    const [status, setStatus] = useState(null);
    const [code, setCode] = useState(null);

    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('ASC');

    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);

    const [buildings, setBuildings] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState(null);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [invoiceToDelete, setInvoiceToDelete] = useState(null);

    const [totalPriceSum, setTotalPriceSum] = useState(null);
    const [paidAmountSum, setPaidAmountSum] = useState(null);
    const [remainingAmountSum, setRemainingAmountSum] = useState(null);

    const fetchInvoices = useCallback(async () => {
        try {
            setLoading(true);
            const params = {};
            params.limit = itemsPerPage;
            params.offset = (currentPage - 1) * itemsPerPage;
            params.client_invoices = false;
            if (code) params.code = code;
            if (status) params.invoice_status = status;
            if (selectedBuilding) params.building_id = selectedBuilding.id;
            if (selectedProvider) params.provider_id = selectedProvider.id;
            if (sortColumn) params.sort_by = sortColumn;
            if (sortDirection) params.sort_direction = sortDirection;

            const result = await api().get("/invoices/", { params });

            setInvoices(result.data.paginated_data);
            setTotalItems(result.data.data_length);
            setTotalPriceSum(result.data.total_price_sum);
            setPaidAmountSum(result.data.paid_amount_sum);
            setRemainingAmountSum(result.data.remaining_amount_sum);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api, currentPage, itemsPerPage, code, status, selectedProvider, selectedBuilding, sortColumn, sortDirection]);

    const fetchProviders = useCallback(async () => {
        try {
            setLoading(true);

            const result = await api().get("/providers/");

            setProviders(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    const fetchBuildings = useCallback(async () => {
        try {
            setLoading(true);

            const result = await api().get("/buildings/");

            setBuildings(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    useEffect(() => {
        fetchProviders();
        fetchBuildings();
    }, []);

    useEffect(() => {
        fetchInvoices();
    }, [currentPage, itemsPerPage]);

    useEffect(() => {
        if (fetchReset) {
            fetchInvoices();
            setFetchReset(false);
        }
    }, [fetchReset]);

    const handleStatusChange = (event) => {
        const { name, value } = event.target;
        setStatus(value);
    };

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
        } else {
            setSortColumn(column);
            setSortDirection('ASC');
        }
        fetchInvoices();
    };

    const handleApplyFilters = () => {
        fetchInvoices();
    };

    const handleResetFilters = () => {
        setStatus(null);
        setSelectedProvider(null);
        setSelectedBuilding(null);
        setCode(null);
        setFetchReset(true);
    };

    const formatDate = (date) => {
        if (!date) {
            return '';
        }
        return dayjs(date).format('DD/MM/YYYY');
    };

    const handleProviderChange = (event, newValue) => {
        setSelectedProvider(newValue);
    };

    const handleBuildingChange = (event, newValue) => {
        setSelectedBuilding(newValue);
    };

    const handleAmountChange = (id, value) => {
        const invoice = invoices.find(inv => inv.id === id);
        const remainingAmount = invoice.total_price - (invoice.paid_amount ?? 0) - (invoice.retention ?? 0);

        let newErrors = { ...errors };

        // Validate the amount
        if (value <= 0) {
            newErrors[id] = "El monto debe ser mayor a 0.";
        } else if (value > remainingAmount) {
            newErrors[id] = `El monto no puede exceder el restante (${remainingAmount.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 })}€).`;
        } else {
            delete newErrors[id];
        }

        setAmounts({
            ...amounts,
            [id]: value
        });

        setErrors(newErrors);
    };
    const handlePayInvoice = async (id) => {
        try {
            const amount = amounts[id];
            if (!amount || amount <= 0) {
                alert("Por favor, introduzca un valor válido.");
                return;
            }
            const result = await api().patch('/invoices/pay_invoice', { invoice_id: id, amount: amount });
            if (result.status < 300) {
                alert('Factura pagada correctamente');
                fetchInvoices();
                setError(null);
            } else {
                alert(`Error ${result.status}: ${result.data.detail}`);
            }
            fetchInvoices();
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    };

    const handleOpenDeleteDialog = (id) => {
        setInvoiceToDelete(id);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setInvoiceToDelete(null);
    };

    const handleDeleteInvoice = async () => {
        try {
            const result = await api().patch('/invoices/disable', { invoice_id: invoiceToDelete });
            if (result.status < 300) {
                alert('Eliminado correctamente');
                fetchInvoices();
                setOpenDeleteDialog(false);
                setError(null);
            } else {
                alert(`Error ${result.status}: ${result.data.detail}`)

            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    };

    const cellStyle = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '8.9%'
    };

    return (
        <div>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    {/* Filters and Sorting */}
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={3} sm={2}>
                                    <TextField
                                        fullWidth
                                        label="Código"
                                        name="code"
                                        value={code || ""}
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </Grid>
                                <Grid item sm={2}>
                                    <Autocomplete
                                        options={buildings}
                                        getOptionLabel={(option) => option.code?.toString() + '-' + option.name?.toString()}
                                        value={selectedBuilding}
                                        onChange={handleBuildingChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Obra"
                                                style={{ maxWidth: '100%' }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item sm={2}>
                                    <Autocomplete
                                        options={providers}
                                        getOptionLabel={(option) => option.code?.toString() + '-' + option.name?.toString()}
                                        value={selectedProvider}
                                        onChange={handleProviderChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Proveedor"
                                                style={{ maxWidth: '100%' }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="status-label">Estado</InputLabel>
                                        <Select
                                            labelId="status-label"
                                            id="status"
                                            name="status"
                                            value={status}
                                            onChange={handleStatusChange}
                                            label="Tipo"
                                        >
                                            <MenuItem value="paid">PAGADA</MenuItem>
                                            <MenuItem value="not_paid">NO PAGADA</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={handleApplyFilters}>
                                        Aplicar Filtros
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="secondary" onClick={handleResetFilters}>
                                        Resetear Filtros
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    {/* Sums Display */}
                    <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{ marginBottom: 3 }}>
                        <Grid item xs={12} sm={4}>
                            <Card elevation={3} sx={{ padding: 1.5 }}>
                                <CardContent>
                                    <Typography variant="subtitle1" align="center" color="textSecondary" gutterBottom>
                                        Total Importe
                                    </Typography>
                                    <Typography variant="h6" align="center" color="primary">
                                        {totalPriceSum ? totalPriceSum.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : '0€'}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Card elevation={3} sx={{ padding: 1.5 }}>
                                <CardContent>
                                    <Typography variant="subtitle1" align="center" color="textSecondary" gutterBottom>
                                        Total Pagado
                                    </Typography>
                                    <Typography variant="h6" align="center" color="primary">
                                        {paidAmountSum ? paidAmountSum.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : '0€'}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Card elevation={3} sx={{ padding: 1.5 }}>
                                <CardContent>
                                    <Typography variant="subtitle1" align="center" color="textSecondary" gutterBottom>
                                        Total Restante
                                    </Typography>
                                    <Typography variant="h6" align="center" color="primary">
                                        {remainingAmountSum ? remainingAmountSum.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : '0€'}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <br />
                    <br />
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={cellStyle}>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('code')} style={{ cursor: 'pointer' }}>
                                                Código {sortColumn === 'code' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('invoice_date')} style={{ cursor: 'pointer' }}>
                                                Fecha {sortColumn === 'invoice_date' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Typography variant="h6" gutterBottom>
                                            Obra
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Typography variant="h6" gutterBottom>
                                            Proveedor
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Typography variant="h6" gutterBottom>
                                            Estado
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('total_price')} style={{ cursor: 'pointer' }}>
                                                Importe {sortColumn === 'total_price' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Typography variant="h6" gutterBottom>
                                            Retención
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Typography variant="h6" gutterBottom>
                                            Pagado
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Typography variant="h6" gutterBottom>
                                            Restante
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Typography variant="h6" gutterBottom>
                                            Cantidad a Pagar
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '15%' }}>
                                        <Typography variant="h6" gutterBottom>
                                            Acciones
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoices.map((item) => {
                                    const remainingAmount = item.total_price - (item.paid_amount ?? 0) - (item.retention ?? 0);
                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell component="th" scope="row" sx={cellStyle}>
                                                {item.code}
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={cellStyle}>
                                                {formatDate(item.invoice_date)}
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={cellStyle}>
                                                <Link to={`/buildings/update/${item.building.id}`}>
                                                    {item.building.code}
                                                </Link>
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={cellStyle}>
                                                <Link to={`/providers/update/${item.provider.id}`}>
                                                    {item.provider.code}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={cellStyle}>
                                                {item.invoice_status === 'paid' ? 'PAGADA' :
                                                    item.invoice_status === 'not_paid' ? 'NO PAGADA' :
                                                        item.invoice_status}
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={cellStyle}>
                                                {item.total_price ? item.total_price.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : null}
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={cellStyle}>
                                                {item.retention ? item.retention.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : null}
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={cellStyle}>
                                                {item.paid_amount ? item.paid_amount.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : 0}
                                            </TableCell>
                                            <TableCell sx={cellStyle}>
                                                {remainingAmount.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€'}
                                            </TableCell>

                                            <TableCell>
                                                <TextField
                                                    type="number"
                                                    value={amounts[item.id] || ''}
                                                    onChange={(e) => handleAmountChange(item.id, parseFloat(e.target.value))}
                                                    fullWidth
                                                    error={!!errors[item.id]}
                                                    helperText={errors[item.id]}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handlePayInvoice(item.id)}
                                                    sx={{ marginRight: '8px' }}
                                                    disabled={remainingAmount <= 0 || !!errors[item.id] || !amounts[item.id]}
                                                >
                                                    Pagar
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => handleOpenDeleteDialog(item.id)}
                                                >
                                                    Borrar
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
            <br />
            <Paginate
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                onPageChange={setCurrentPage}
            />
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
            >
                <DialogTitle>Confirmar Borrado</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Estás seguro de que quieres borrar esta factura?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDeleteInvoice} color="secondary" autoFocus>
                        Borrar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ListProviderInvoices;
