import React, { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useAuth } from "../pages/login/OAuth";

const InvoiceRetentionTable = ({
    endpoint,
    text = 'Mostrar pdf',
}) => {
    const [loading, setLoading] = useState(false);
    const { api } = useAuth();

    const showPdf = async () => {
        try {
            setLoading(true);
            const response = await api().get(`${endpoint}`, { responseType: 'blob' });
            if (response.status < 300) {
                const pdfUrl = URL.createObjectURL(response.data);
                window.open(pdfUrl, '_blank');
            } else {
                alert(`Error ${response.status}: ${response.data.detail}`);
            }
        } catch (error) {
            alert(`Error: ${error.response?.data?.detail || error.message}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Button
            variant="contained"
            sx={{ backgroundColor: 'green', color: 'white', marginRight:'10px'}}
            onClick={showPdf}
            disabled={loading} // Disable button when loading
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
        >
            {loading ? '' : text}
        </Button>
    );
};

export default InvoiceRetentionTable;
