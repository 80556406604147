import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { TextField, Button, Typography, Grid, Autocomplete, CircularProgress, Paper } from '@mui/material';

function EditProduct() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const { api } = useAuth();
    const [error, setError] = useState(null);

    const [types, setTypes] = useState([]);
    const [selectedType, setSelectedType] = useState(null);

    const [productForm, setProductForm] = useState({
        name: '',
        type_id: '',
    });

    const fetchProduct = useCallback(async () => {
        try {
            const result = await api().get(`/products/${id}`);
            setProductForm(result.data);
            setProductForm(prevState => ({ ...prevState, product_id: id }));
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo producto");
            setLoading(false);
        }
    }, [api, id]);

    const fetchTypes = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().get("/products/types");
            setTypes(result.data);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data?.detail)}`);
            setLoading(false);
        }
    }, [api]);

    useEffect(() => {
        fetchTypes();
        fetchProduct();
    }, []);

    useEffect(() => {
        if (productForm.type_id && types.length > 0) {
            const selected = types.find(type => type.id === productForm.type_id);
            setSelectedType(selected);
        }
    }, [productForm.type_id, types]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProductForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleTypeChange = (event, newValue) => {
        setSelectedType(newValue);
        setProductForm(prevState => ({
            ...prevState,
            type_id: newValue ? newValue.id : ''
        }));
    };

    const validateForm = () => {
        if (!productForm.name) {
            return "Por favor, añada un nombre";
        }
        if (!productForm.type_id) {
            return "Por favor, añada un tipo";
        }
        return null;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationError = validateForm();
        if (validationError) {
            setError(validationError);
            return;
        }
        try {
            setLoading(true);
            const result = await api().patch(`/products/update`, productForm);
            if (result.status < 300) {
                alert('Producto actualizado correctamente');
                fetchProduct();
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response?.data?.detail)}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Grid container justifyContent="center">
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Paper elevation={3} style={{ padding: 20 }}>
                        <Typography variant="h5" gutterBottom>
                            Actualizar Producto
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Nombre"
                                        name="name"
                                        value={productForm.name}
                                        onChange={handleChange}
                                        margin="normal"
                                        inputProps={{ maxLength: 36 }}
                                        InputLabelProps={{ shrink: !!productForm.name }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={types}
                                        getOptionLabel={(option) => option.name?.toString()}
                                        value={selectedType}
                                        onChange={handleTypeChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Tipos"
                                                style={{ maxWidth: '100%' }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <br />
                            <Button type="submit" variant="contained" color="primary" disabled={loading} fullWidth>
                                Actualizar
                            </Button>
                        </form>
                        {error && (
                            <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                {error}
                            </Typography>
                        )}
                    </Paper>
                </Grid>
            )}
        </Grid>
    );
}

export default EditProduct;
