import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

const ProtectedRoute = ({
  redirectPath = '/',
  children,
}) => {

  const authenticated = localStorage.getItem('Authenticated');
  if (authenticated) {
    return children ? children : <Outlet />;
  }
  return <Navigate to={redirectPath} replace />;
};

export default ProtectedRoute