import React, { useState, useEffect, useCallback } from "react";
import Paginate from '../../components/Paginate';
import { Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import dayjs from 'dayjs';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Autocomplete,
    TextField,
    Button,
    Grid,
    CircularProgress,
    Typography
} from "@mui/material";


function ListOffers() {
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fetchReset, setFetchReset] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(null);

    const [offers, setOffers] = useState([]);

    const [name, setName] = useState(null);
    const [status, setStatus] = useState(null);
    const [minBudget, setMinBudget] = useState(null);
    const [maxBudget, setMaxBudget] = useState(null);
    const [code, setCode] = useState(null);

    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('ASC');

    const fetchOffers = useCallback(async () => {
        try {
            setLoading(true);
            const params = {};
            params.limit = itemsPerPage;
            params.offset = (currentPage - 1) * itemsPerPage
            if (name) params.name = name;
            if (code) params.code = code;
            if (status) params.offer_status = status;
            if (minBudget) params.min_budget = minBudget;
            if (maxBudget) params.max_budget = maxBudget;
            if (sortColumn) params.sort_by = sortColumn;
            if (sortDirection) params.sort_direction = sortDirection;
            if (selectedClient) params.client_id = selectedClient.id;

            const result = await api().get("/offers/", { params });

            setOffers(result.data[0]);
            setTotalItems(result.data[1]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api, currentPage, itemsPerPage, code, name, minBudget, maxBudget, status, sortColumn, sortDirection])

    const fetchClients = useCallback(async () => {
        try {
            setLoading(true);

            const result = await api().get("/clients/");

            setClients(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    useEffect(() => {
        fetchClients();
    }, []);

    useEffect(() => {
        fetchOffers();
        fetchClients();
    }, [currentPage, itemsPerPage]);

    useEffect(() => {
        if (fetchReset) {
            fetchOffers();
            setFetchReset(false);
        }
    }, [fetchReset]);

    const handleStatusChange = (event) => {
        const { name, value } = event.target;
        setStatus(value);
    };

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
        } else {
            setSortColumn(column);
            setSortDirection('ASC');
        }
        fetchOffers();
    };
    const handleClientChange = (event, newValue) => {
        setSelectedClient(newValue);
    };
    const handleApplyFilters = () => {
        fetchOffers();
    };
    const handleResetFilters = () => {
        setName(null);
        setStatus(null);
        setMinBudget(null);
        setMaxBudget(null);
        setSelectedClient(null);
        setFetchReset(true);
    };
    const formatDate = (date) => {
        if (!date) {
            return '';
        }
        return dayjs(date).format('DD/MM/YYYY');
    };
    const cellStyle = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '75px'
    };
    return (
        <div>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={3} sm={2}>
                                    <TextField
                                        fullWidth
                                        label="Código"
                                        name="code"
                                        value={code || ""}
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <TextField
                                        fullWidth
                                        label="Nombre"
                                        name="name"
                                        value={name || ""}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item sm={2}>
                                    <Autocomplete
                                        options={clients}
                                        getOptionLabel={(option) => option.name?.toString()}
                                        value={selectedClient}
                                        onChange={handleClientChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Cliente"
                                                style={{ maxWidth: '100%' }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <TextField
                                        fullWidth
                                        label="Presupuesto min"
                                        name="minBudget"
                                        value={minBudget || ""}
                                        onChange={(e) => setMinBudget(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <TextField
                                        fullWidth
                                        label="Presupuesto max"
                                        name="maxBudget"
                                        value={maxBudget || ""}
                                        onChange={(e) => setMaxBudget(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="status-label">Estado</InputLabel>
                                        <Select
                                            labelId="status-label"
                                            id="status"
                                            name="status"
                                            value={status}
                                            onChange={handleStatusChange}
                                            label="Tipo"
                                        >
                                            <MenuItem value="pending">PENDIENTE</MenuItem>
                                            <MenuItem value="accepted">ACEPTADA</MenuItem>
                                            <MenuItem value="rejected" >RECHAZADA</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={handleApplyFilters}>
                                        Aplicar Filtros
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="secondary" onClick={handleResetFilters}>
                                        Resetear Filtros
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <br />
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('code')} style={{ cursor: 'pointer' }}>
                                                Código {sortColumn === 'code' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>
                                                Nombre {sortColumn === 'name' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('budget')} style={{ cursor: 'pointer' }}>
                                                Presupuesto {sortColumn === 'budget' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Cliente</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('start_date')} style={{ cursor: 'pointer' }}>
                                                Fecha inicio {sortColumn === 'start_date' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('end_date')} style={{ cursor: 'pointer' }}>
                                                Fecha final {sortColumn === 'end_date' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Estado</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {offers.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            <Link to={`/offers/update/${item.id}`}>
                                                {item.code}
                                            </Link>
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            {item.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            {item.budget ? item.budget.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : null}
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            {item.client.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            {formatDate(item.start_date)}
                                        </TableCell>
                                        <TableCell sx={cellStyle}>
                                            {formatDate(item.end_date)}
                                        </TableCell>
                                        <TableCell sx={cellStyle}>
                                            {item.offer_status === 'pending' ? 'PENDIENTE' :
                                                item.offer_status === 'accepted' ? 'ACEPTADA' :
                                                    item.offer_status === 'rejected' ? 'RECHAZADA' :
                                                        item.offer_status}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>

            )}
            <br />
            <br />
            <br />
            <Paginate
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                onPageChange={setCurrentPage}
            />
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </div>
    )
}
export default ListOffers;