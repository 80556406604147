import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import {
    Button,
    TextField,
    Grid,
    Box,
    Paper,
    Typography,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    CircularProgress
} from "@mui/material";

function NewWorker() {
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [workerForm, setWorkerForm] = useState({
        email: null,
        name: null,
        surname: null,
        documentation: null,
        doc_type: null,
        address: null,
        telephone: null
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setWorkerForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateForm = () => {
        if (!workerForm.email) {
            return "Por favor, añada un email";
        }
        if (!workerForm.name) {
            return "Por favor, añada un nombre";
        }
        if (workerForm.email.length > 36) {
            return "El email no debe exceder los 36 caracteres";
        }
        if (workerForm.name.length > 36) {
            return "El nombre no debe exceder los 36 caracteres";
        }
        if (workerForm.surname && workerForm.surname.length > 36) {
            return "Los apellidos no deben exceder los 36 caracteres";
        }
        if (workerForm.documentation && workerForm.documentation.length > 25) {
            return "La documentación no puede exceder los 25 caracteres";
        }
        if (workerForm.documentation && !workerForm.doc_type) {
            return "Si especifica una documentacion tiene que marcar tambien el tipo";
        }
        if (!workerForm.documentation && workerForm.doc_type) {
            return "Si marca un tipo de documentacion tiene que especificar la documentacion";
        }
        if (workerForm.address && workerForm.address.length > 150) {
            return "La dirección no debe exceder los 150 caracteres";
        }
        if (workerForm.telephone && workerForm.telephone.length > 15) {
            return "El teléfono no debe exceder los 15 caracteres";
        }
        return null;
    };

    const createWorker = async (event) => {
        event.preventDefault();
        const validationError = validateForm();
        if (validationError) {
            setError(validationError);
            return;
        }
        try {
            setLoading(true);

            const result = await api().post('/workers/new', workerForm);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    alert("Operario creado correctamente");
                    navigate(`/workers/list`);
                    setError(null);
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '120%' }}>
            <Paper elevation={3} style={{ padding: 20, width: '40%' }}>
                <Typography component="h2" variant="h5">
                    Nuevo Operario
                </Typography>
                <br></br>
                <form onSubmit={createWorker}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Nombre"
                                name="name"
                                value={workerForm.name}
                                onChange={handleChange}
                                margin="normal"
                                inputProps={{ maxLength: 36 }}
                            />
                            <TextField
                                fullWidth
                                label="Email"
                                name="email"
                                value={workerForm.email}
                                onChange={handleChange}
                                margin="normal"
                                inputProps={{ maxLength: 36 }}
                            />
                            <TextField
                                fullWidth
                                label="Apellidos"
                                name="surname"
                                value={workerForm.surname}
                                onChange={handleChange}
                                margin="normal"
                                inputProps={{ maxLength: 36 }}
                            />
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    margin="normal"
                                    style={{ width: "100%" }}
                                >
                                    <InputLabel id="document-type-label">
                                        Documentación
                                    </InputLabel>
                                    <Select
                                        labelId="document-type-label"
                                        id="document-type"
                                        label='Tipo documentación'
                                        value={workerForm.doc_type}
                                        onChange={handleChange}
                                        margin={'1'}
                                        name="doc_type"
                                        inputProps={{ id: 'document-type' }}
                                    >
                                        <MenuItem value="dni">DNI</MenuItem>
                                        <MenuItem value="nie_others">NIE/Otros</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <TextField
                                fullWidth
                                label="Documentación"
                                name="documentation"
                                value={workerForm.documentation}
                                onChange={handleChange}
                                margin="normal"
                                inputProps={{ maxLength: 25 }}
                            />
                            <TextField
                                fullWidth
                                label="Dirección"
                                name="address"
                                value={workerForm.address}
                                onChange={handleChange}
                                margin="normal"
                                inputProps={{ maxLength: 150 }}
                            />
                            <TextField
                                fullWidth
                                label="Teléfono"
                                name="telephone"
                                value={workerForm.telephone}
                                onChange={handleChange}
                                margin="normal"
                                inputProps={{ maxLength: 15 }}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <Button type="submit" variant="contained" color="primary" disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : 'Crear'}
                            </Button>
                        </Box>
                    </Grid>
                </form>
                {error && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error}
                    </Typography>
                )}
            </Paper >
        </div>
    );
}

export default NewWorker;
