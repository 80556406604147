import React, { useState, useEffect, useCallback } from "react";
import Paginate from '../../components/Paginate';
import { Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import "dayjs/locale/es";
import { FaInfoCircle, FaTrash } from 'react-icons/fa';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    CircularProgress,
    DialogActions,
    Typography,
    Grid,
    TextField,
    Autocomplete
} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

function ListClockIns() {
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fetchReset, setFetchReset] = useState(false);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(null);

    const [clockIns, setClockIns] = useState([]);

    const [buildings, setBuildings] = useState([]);
    const [buildingSelected, setBuildingSelected] = useState(null);

    const [workers, setWorkers] = useState([]);
    const [workerSelected, setWorkerSelected] = useState(null);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const fetchClockIns = useCallback(async () => {
        try {
            setLoading(true);
            const params = {};
            params.limit = itemsPerPage;
            params.offset = (currentPage - 1) * itemsPerPage;
            if (buildingSelected) {
                params.building_id = buildingSelected.id;
            }
            if (workerSelected) {
                params.worker_id = workerSelected.id;
            }

            if (startDate) {
                const startDateMidday = dayjs(startDate).hour(12).minute(0).second(0);
                params.start_date = startDateMidday.utc().format('YYYY-MM-DD');
            }
            if (endDate) {
                const endDateMidday = dayjs(endDate).hour(12).minute(0).second(0);
                params.end_date = endDateMidday.utc().format('YYYY-MM-DD');
            }

            const result = await api().get("/clock_ins/", { params });
            
            setClockIns(result.data[0]);
            setTotalItems(result.data[1]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api, currentPage, itemsPerPage, buildingSelected, workerSelected, startDate, endDate]);

    const fetchBuildings = useCallback(async () => {
        try {
            setLoading(true);

            const result = await api().get("/buildings/");

            setBuildings(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    const fetchWorkers = useCallback(async () => {
        try {
            setLoading(true);

            const result = await api().get("/workers/");

            setWorkers(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    useEffect(() => {
        fetchClockIns();
        fetchBuildings();
        fetchWorkers();
    }, [currentPage, itemsPerPage]);

    useEffect(() => {
        if (fetchReset) {
            fetchClockIns();
            setFetchReset(false);
        }
    }, [fetchReset]);

    const handleDeleteItem = async () => {
        try {
            const result = await api().delete(`/clock_ins/delete`, { clock_in_id: deleteItemId });
            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    fetchClockIns();
                    handleCloseDeleteDialog();
                }
            } else {
                alert(`Error ${result.data.detail}`);
            }
        } catch (error) {
            alert("Error", error);
        }
    };

    const handleOpenDeleteDialog = (itemId) => {
        setDeleteItemId(itemId);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleWorkerChange = (event, newValue) => {
        setWorkerSelected(newValue);
    };

    const handleBuildingChange = (event, newValue) => {
        setBuildingSelected(newValue);
    };
    const handleStartDateChange = (newValue) => {
        setStartDate(newValue);
    };

    const handleEndDateChange = (newValue) => {
        setEndDate(newValue);
    };

    const handleResetFilters = () => {
        setWorkerSelected(null);
        setBuildingSelected(null);
        setStartDate(null);
        setEndDate(null);
        setFetchReset(true);
    };

    const handleApplyFilters = () => {
        fetchClockIns();
    };

    const formatDate = (datetime) => {
        const date = new Date(datetime);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString(undefined, options);
    };

    const formatTime = (datetime) => {
        const date = new Date(datetime);
        const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
        return date.toLocaleTimeString(undefined, options);
    };

    const cellStyle = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '75px'
    };

    return (
        <div>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item sm={2}>
                                    <Autocomplete
                                        options={workers}
                                        getOptionLabel={(option) => option.name?.toString()}
                                        value={workerSelected}
                                        onChange={handleWorkerChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Operarios"
                                                style={{ maxWidth: '100%' }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item sm={2}>
                                    <Autocomplete
                                        options={buildings}
                                        getOptionLabel={(option) => option.name?.toString()}
                                        value={buildingSelected}
                                        onChange={handleBuildingChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Obras"
                                                style={{ maxWidth: '100%' }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale="es"
                                        localeText={{
                                            cancelButtonLabel: "cancelar",
                                            okButtonLabel: "Ok",
                                            datePickerToolbarTitle: 'Seleccionar',
                                        }}>
                                        <MobileDatePicker
                                            label="Fecha de Inicio"
                                            closeOnSelect={true}
                                            value={startDate}
                                            onChange={handleStartDateChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale="es"
                                        localeText={{
                                            cancelButtonLabel: "cancelar",
                                            okButtonLabel: "Ok",
                                            datePickerToolbarTitle: 'Seleccionar',
                                        }}>
                                        <MobileDatePicker
                                            label="Fecha de Fin"
                                            value={endDate}
                                            onChange={handleEndDateChange}
                                            closeOnSelect={true}
                                            renderInput={(params) => <TextField {...params} />}

                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={handleApplyFilters}>
                                        Aplicar Filtros
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="secondary" onClick={handleResetFilters}>
                                        Resetear Filtros
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                    <br />
                    <br />
                    <br />
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Obra</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Operario</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Fecha</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Hora</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clockIns.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            {item.building.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            {item.worker.name}
                                        </TableCell>
                                        <TableCell sx={cellStyle}>
                                            {formatDate(item.clock_in_datetime)}
                                        </TableCell>
                                        <TableCell sx={cellStyle}>
                                            {formatTime(item.clock_in_datetime)}
                                        </TableCell>

                                    </TableRow>
                                ))}

                                <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                                    <DialogTitle>¿Estás seguro de que quieres borrar este fichaje?</DialogTitle>
                                    <DialogActions>
                                        <Button onClick={handleCloseDeleteDialog} color="primary">
                                            Volver
                                        </Button>
                                        <Button onClick={handleDeleteItem} color="error">
                                            Eliminar
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )
            }
            <br />
            <br />
            <br />
            <Paginate
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                onPageChange={setCurrentPage}
            />
            {
                error && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error}
                    </Typography>
                )
            }
        </div >
    );
}

export default ListClockIns;
