import React, { useState, useEffect, useCallback } from "react";
import { Button, Typography } from "@mui/material";

function Paginate({ totalItems, itemsPerPage, onPageChange }) {
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        onPageChange(currentPage);
    }, [currentPage, onPageChange]);

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    return (
        <div style={{ textAlign: 'center' }}>
            <Button onClick={handlePreviousPage} disabled={currentPage === 1}>Anterior</Button>
            {pageNumbers.map((page, index) =>
                Math.abs(currentPage - page) <= 3 && (
                    <Button
                        key={page}
                        onClick={() => handlePageClick(page)}
                        style={{
                            backgroundColor: currentPage === page ? 'rgb(67, 120, 182)' : '', // Color azul para la página actual
                            color: currentPage === page ? 'white' : 'black', // Texto blanco para la página actual, negro para las demás
                            borderRadius: '50%', // Botones circulares
                            width: '30px', // Tamaño uniforme para todos los botones
                            height: '30px', // Tamaño uniforme para todos los botones
                            textAlign: 'center',
                            lineHeight: '30px', // Asegúrate de que esto coincida con el height para centrar verticalmente
                            padding: 0, // Elimina cualquier padding adicional
                            display: 'inline-block',
                            margin: '0 5px',
                            fontSize: currentPage === page ? '1em' : '0.85em', // Texto más grande para la página actual
                            overflow: 'hidden'
                        }}
                    >
                        {page}
                    </Button>
                )
            )}
            <Button onClick={handleNextPage} disabled={currentPage === totalPages}>Siguiente</Button>
        </div>
    );
}

export default Paginate;
