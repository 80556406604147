import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../login/OAuth";
import { useParams } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    CircularProgress,
    Typography,
    Checkbox,
    Box
} from "@mui/material";
import dayjs from 'dayjs'; // Import dayjs for date manipulation

function NewClockIn() {
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    const [workers, setWorkers] = useState([]);
    const [selectedWorkers, setSelectedWorkers] = useState([]);

    const fetchWorkers = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().get(`/buildings/get_workers/${id}`);
            setWorkers(result.data); // Ensure workers is an array
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response?.data?.detail || error.message)}`);
            setLoading(false);
        }
    }, [api, id]);

    useEffect(() => {
        fetchWorkers();
    }, []);

    const handleCheckboxChange = (event, workerId) => {
        if (event.target.checked) {
            setSelectedWorkers([...selectedWorkers, workerId]);
        } else {
            setSelectedWorkers(selectedWorkers.filter(id => id !== workerId));
        }
    };

    const handleSelectAll = () => {
        const today = dayjs();
        const selectableWorkers = workers.filter(worker => !isSameDay(today, worker.last_clock_in));
        setSelectedWorkers(selectableWorkers.map(worker => worker.id));
    };

    const handleDeselectAll = () => {
        setSelectedWorkers([]);
    };

    const handleFichar = async () => {
        try {
            const response = await api().post('/clock_ins/new', { building_id: id, workers_ids: selectedWorkers });
            if (response.status === 200) {
                alert('Fichaje realizado con éxito');
                fetchWorkers();
            } else {
                alert('Error al realizar el fichaje');
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response?.data?.detail || error.message)}`);
        }
    };

    const isSameDay = (date1, date2) => {
        return dayjs(date1).isSame(dayjs(date2), 'day');
    };

    const today = dayjs();

    return (
        <div>
            <Box display="flex" justifyContent="space-between" mb={2}>
                <Box>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleSelectAll}
                        disabled={workers.length === 0}
                        sx={{ mr: 2 }}
                    >
                        Marcar Todos
                    </Button>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        onClick={handleDeselectAll}
                        disabled={workers.length === 0}
                    >
                        Desmarcar Todos
                    </Button>
                </Box>
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleFichar} 
                    disabled={selectedWorkers.length === 0}
                >
                    Fichar
                </Button>
            </Box>
            <TableContainer component={Paper}>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        {workers.length > 0 ? (
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>
                                            <Typography variant="h6" gutterBottom>Nombre</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6" gutterBottom>Email</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6" gutterBottom>Teléfono</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6" gutterBottom>Dirección</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {workers.map(item => (
                                        <TableRow key={item.id}>
                                            <TableCell>
                                                {!isSameDay(today, item.last_clock_in) && (
                                                    <Checkbox
                                                        checked={selectedWorkers.includes(item.id)}
                                                        onChange={(event) => handleCheckboxChange(event, item.id)}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.name} {item.surname}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.email}
                                            </TableCell>
                                            <TableCell>
                                                {item.telephone}
                                            </TableCell>
                                            <TableCell>
                                                {item.address}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        ) : (
                            <Typography variant="h6" align="center" gutterBottom>
                                Todavía no hay operarios.
                            </Typography>
                        )}
                    </>
                )}
            </TableContainer>
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </div>
    );
}

export default NewClockIn;
