import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import {
    Button,
    TextField,
    Grid,
    Box,
    Paper,
    Autocomplete,
    Typography,
    CircularProgress
} from "@mui/material";

function NewProduct() {
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [types, setTypes] = useState([]);
    const [selectedType, setSelectedType] = useState(null);

    const [productForm, setProductForm] = useState({
        name: null,
        type_id: null,
    });

    const fetchTypes = useCallback(async () => {
        try {
            setLoading(true);

            const result = await api().get("/products/types");

            setTypes(result.data);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api])


    useEffect(() => {
        fetchTypes();
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setProductForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleTypeChange = (event, newValue) => {
        setSelectedType(newValue);
        setProductForm(prevState => ({
            ...prevState,
            type_id: newValue ? newValue.id : ''
        }));
    };

    const validateForm = () => {
        if (!productForm.name) {
            return "Por favor, añada un nombre";
        }
        if (!productForm.type_id) {
            return "Por favor, añada un tipo";
        }
        if (productForm.name > 36) {
            return "El nombre no debe exceder los 36 caracteres";
        }
        return null;
    };

    const createProduct = async (event) => {
        event.preventDefault();
        const validationError = validateForm();
        if (validationError) {
            setError(validationError);
            return;
        }
        try {
            setLoading(true);

            const result = await api().post('/products/new', productForm);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    alert("Producto creado correctamente");
                    navigate(`/products/list`);
                    setError(null);
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '120%' }}>
            <Paper elevation={3} style={{ padding: 20, width: '40%' }}>
                <Typography component="h2" variant="h5">
                    Nuevo Producto
                </Typography>
                <br></br>
                <form onSubmit={createProduct}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Nombre"
                                name="name"
                                value={productForm.name}
                                onChange={handleChange}
                                margin="normal"
                                inputProps={{ maxLength: 36 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={types}
                                getOptionLabel={(option) => option.name?.toString()}
                                value={selectedType}
                                onChange={handleTypeChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Tipos"
                                        style={{ maxWidth: '100%' }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <Button type="submit" variant="contained" color="primary" disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : 'Crear'}
                            </Button>
                        </Box>
                    </Grid>
                </form>
                {error && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error}
                    </Typography>
                )}
            </Paper >
        </div>
    );
}

export default NewProduct;
