import React, { useState, useEffect, useCallback } from "react";
import Paginate from '../../components/Paginate';
import { Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { FaInfoCircle, FaTrash } from 'react-icons/fa';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    Modal,
    TableHead,
    TableRow,
    Autocomplete,
    Paper,
    TextField,
    Button,
    Dialog,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Grid,
    DialogTitle,
    CircularProgress,
    DialogActions,
    Typography
} from "@mui/material";


function ListProducts() {
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fetchReset, setFetchReset] = useState(false);

    const [modalError, setModalError] = useState(null);
    const [modalLoading, setModalLoading] = useState(false);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(null);

    const [openAssignModal, setOpenAssignModal] = useState(false);
    const [assignProductId, setAssignProductId] = useState(null);
    const [selectedWorkerForAssignment, setSelectedWorkerForAssignment] = useState(null);

    const [products, setProducts] = useState([]);
    const [workers, setWorkers] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [types, setTypes] = useState([]);

    const [name, setName] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [status, setStatus] = useState(null);
    const [selectedWorker, setSelectedWorker] = useState(null);
    const [selectedBuilding, setSelectedBuilding] = useState(null);

    const fetchProducts = useCallback(async () => {
        try {
            setLoading(true);
            const params = {};
            params.limit = itemsPerPage;
            params.offset = (currentPage - 1) * itemsPerPage
            if (name) {
                params.name = name;
            }
            if (selectedType) {
                params.type_id = selectedType.id;
            }
            if (status) {
                params.product_status = status;
            }
            if (selectedWorker) {
                params.worker_id = selectedWorker.id;
            }
            if (selectedBuilding) {
                params.building_id = selectedBuilding.id;
            }

            const result = await api().get("/products/", { params });

            setProducts(result.data[0]);
            setTotalItems(result.data[1]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api, currentPage, itemsPerPage, name])
    const fetchBuildings = useCallback(async () => {
        try {
            setLoading(true);

            const result = await api().get("/buildings/");

            setBuildings(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api])
    const fetchWorkers = useCallback(async () => {
        try {
            setLoading(true);

            const result = await api().get("/workers/");

            setWorkers(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api])
    const fetchTypes = useCallback(async () => {
        try {
            setLoading(true);

            const result = await api().get("/products/types");

            setTypes(result.data);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api])

    useEffect(() => {
        fetchProducts();
        fetchBuildings();
        fetchWorkers();
        fetchTypes();
    }, [currentPage, itemsPerPage]);

    useEffect(() => {
        if (fetchReset) {
            fetchProducts();
            setFetchReset(false);
        }
    }, [fetchReset]);

    const handleDeleteItem = async () => {
        try {
            const result = await api().patch(`/products/disable`, { product_id: deleteItemId })
            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    fetchProducts();
                    handleCloseDeleteDialog();
                }
            } else {
                alert(`Error ${result.data.detail}`);
            }
        } catch (error) {
            alert("Error", error);
        }

    };

    const handleOpenDeleteDialog = (itemId) => {
        setDeleteItemId(itemId);
        setOpenDeleteDialog(true);
    };
    const handleOpenAssignModal = (productId) => {
        setAssignProductId(productId);
        setOpenAssignModal(true);
    };

    const handleCloseAssignModal = () => {
        setOpenAssignModal(false);
        setModalError(null);
        setSelectedWorkerForAssignment(null);
    };

    const handleWorkerForAssignmentChange = (event, newValue) => {
        setModalError(null);
        setSelectedWorkerForAssignment(newValue);
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    const handleApplyFilters = () => {
        fetchProducts();
    };
    const handleWorkerChange = (event, newValue) => {
        setSelectedWorker(newValue);
    };
    const handleBuildingChange = (event, newValue) => {
        setSelectedBuilding(newValue);
    };
    const handleTypeChange = (event, newValue) => {
        setSelectedType(newValue);
    };
    const handleStatusChange = (event) => {
        const { name, value } = event.target;
        setStatus(value);
    };

    const handleResetFilters = () => {
        setName(null);
        setSelectedType(null);
        setStatus(null);
        setSelectedWorker(null);
        setSelectedBuilding(null);
        setFetchReset(true);
    };
    const handleAssignWorker = async () => {
        setModalLoading(true);
        try {
            const result = await api().post(`/allocations/start`, {
                product_id: assignProductId,
                worker_id: selectedWorkerForAssignment.id
            });

            if (result.status < 300) {
                fetchProducts();
                setModalError(null);
                handleCloseAssignModal(false);
            } else {
                setModalError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setModalError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setModalLoading(false);
        }
    };
    const handleUnassignProduct = async (productId) => {
        try {
            console.log(productId)
            const result = await api().patch(`/allocations/finish`, { product_id: productId });

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    fetchProducts();
                    handleCloseAssignModal();
                }
            } else {
                alert(`Error ${result.data.detail}`);
            }
        } catch (error) {
            alert("Error", error);
        }
    };

    const cellStyle = {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '15px',
    };
    return (
        <div>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={3} sm={2}>
                                    <TextField
                                        fullWidth
                                        label="Nombre"
                                        name="name"
                                        value={name || ""}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <Autocomplete
                                        options={types}
                                        getOptionLabel={(option) => option.name?.toString()}
                                        value={selectedType}
                                        onChange={handleTypeChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Tipos"
                                                style={{ maxWidth: '100%' }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="status-label">Estado</InputLabel>
                                        <Select
                                            labelId="status-label"
                                            id="status"
                                            name="status"
                                            value={status}
                                            onChange={handleStatusChange}
                                            label="Tipo"
                                        >
                                            <MenuItem value="free">LIBRES</MenuItem>
                                            <MenuItem value="asigned">ASIGNADOS</MenuItem>
                                            <MenuItem value="Todos" >TODOS</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <Autocomplete
                                        options={workers}
                                        getOptionLabel={(option) => option.name?.toString()}
                                        value={selectedWorker}
                                        onChange={handleWorkerChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Operarios"
                                                style={{ maxWidth: '100%' }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <Autocomplete
                                        options={buildings}
                                        getOptionLabel={(option) => option.name?.toString()}
                                        value={selectedBuilding}
                                        onChange={handleBuildingChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Obras"
                                                style={{ maxWidth: '100%' }}
                                            />
                                        )}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={handleApplyFilters}>
                                        Aplicar Filtros
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="secondary" onClick={handleResetFilters}>
                                        Resetear Filtros
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                    <br />
                    <br />
                    <br />
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Nombre</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Tipo</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Estado</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Ocupado por</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Obra</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Acciones</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {products.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            {item.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            {item.type}
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            {item.product_status === 'free' ? 'LIBRE' : item.product_status === 'asigned' ? 'ASIGNADO' : item.product_status}
                                        </TableCell>
                                        {item.last_allocation ? (
                                            <>
                                                <TableCell component="th" scope="row" sx={cellStyle}>
                                                    {item.last_allocation.worker ? item.last_allocation.worker : '-'}
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={cellStyle}>
                                                    {item.last_allocation.building ? item.last_allocation.building : '-'}
                                                </TableCell>
                                            </>
                                        ) : (
                                            <>
                                                <TableCell component="th" scope="row" sx={cellStyle}>-</TableCell>
                                                <TableCell component="th" scope="row" sx={cellStyle}>-</TableCell>
                                            </>
                                        )}
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            <div style={{ display: 'flex', gap: '8px' }}>
                                                <Button
                                                    onClick={() => handleOpenAssignModal(item.id)}
                                                    variant="contained"
                                                    color="info"
                                                    disabled={item.product_status === 'asigned'}
                                                    sx={{
                                                        height: '40px',
                                                        padding: '8px 16px',
                                                        minWidth: '70px',
                                                        flex: '0 1 auto'
                                                    }}
                                                >
                                                    Asignar
                                                </Button>
                                                <Button
                                                    onClick={() => handleUnassignProduct(item.id)}
                                                    variant="contained"
                                                    color="info"
                                                    disabled={item.product_status === 'free'}
                                                    sx={{
                                                        height: '40px',
                                                        padding: '8px 16px',
                                                        minWidth: '70px',
                                                        flex: '0 1 auto'
                                                    }}
                                                >
                                                    DESASIGNAR
                                                </Button>
                                                <Link to={`/products/update/${item.id}`} style={{ textDecoration: 'none' }}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{
                                                            height: '40px',
                                                            padding: '8px 16px',
                                                            minWidth: '40px',
                                                            flex: '0 1 auto'
                                                        }}
                                                    >
                                                        <FaInfoCircle />
                                                    </Button>
                                                </Link>
                                                <Button
                                                    onClick={() => handleOpenDeleteDialog(item.id)}
                                                    variant="contained"
                                                    color="error"
                                                    sx={{
                                                        height: '40px',
                                                        padding: '8px 16px',
                                                        minWidth: '40px',
                                                        flex: '0 1 auto'
                                                    }}
                                                >
                                                    <FaTrash />
                                                </Button>
                                            </div>
                                        </TableCell>


                                    </TableRow>
                                ))}

                                <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                                    <DialogTitle>¿Estás seguro de que quieres borrar este producto?</DialogTitle>
                                    <DialogActions>
                                        <Button onClick={handleCloseDeleteDialog} color="primary">
                                            Volver
                                        </Button>
                                        <Button onClick={handleDeleteItem} color="error">
                                            Eliminar
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Modal
                                    open={openAssignModal}
                                    onClose={handleCloseAssignModal}
                                    aria-labelledby="asignar-modal-title"
                                    aria-describedby="asignar-modal-description"
                                >
                                    <Paper sx={{
                                        position: 'absolute',
                                        width: 400,
                                        bgcolor: 'background.paper',
                                        boxShadow: 24,
                                        p: 4,
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)'
                                    }}>
                                        <Typography id="asignar-modal-title" variant="h6" component="h2" gutterBottom>
                                            Asignar trabajador
                                        </Typography>
                                        <Autocomplete
                                            options={workers}
                                            getOptionLabel={(option) => option.name?.toString()}
                                            value={selectedWorkerForAssignment}
                                            onChange={handleWorkerForAssignmentChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Selecciona un trabajador"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            )}
                                        />
                                        {modalError && (
                                            <Typography variant="body2" color="error" style={{ marginBottom: 10 }}>
                                                {modalError}
                                            </Typography>
                                        )}
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                                            <Button onClick={handleCloseAssignModal} color="primary" variant="text" sx={{ marginRight: 2 }}>
                                                Cancelar
                                            </Button>
                                            <Button onClick={handleAssignWorker} color="primary" variant="contained">
                                                Asignar
                                            </Button>
                                        </div>
                                    </Paper>
                                </Modal>


                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
            <br />
            <br />
            <br />
            <Paginate
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                onPageChange={setCurrentPage}
            />
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </div>
    )
}
export default ListProducts;