import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import {
    Button,
    TextField,
    Grid,
    Box,
    Paper,
    Typography,
    CircularProgress
} from "@mui/material";

function NewProvider() {
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [providerForm, setProviderForm] = useState({
        email: null,
        name: '',
        address: null,
        cif: null,
        country: null,
        cp: null,
        autonomus_community: null,
        province: null,
        telephone_number: null
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setProviderForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateForm = () => {
        if (!providerForm.name) {
            return "Por favor, añada un nombre";
        }
        if (providerForm.name.length > 36) {    
            return "El nombre no debe exceder los 36 caracteres";
        }
        if (providerForm.email && providerForm.email.length > 36) {
            return "El email no debe exceder los 36 caracteres";
        }
        if (providerForm.country && providerForm.country.length > 36) {
            return "El pais no debe exceder los 36 caracteres";
        }
        if (providerForm.cp && providerForm.cp.length > 9) {
            return "El codigo postal no debe exceder los 9 caracteres";
        }
        if (providerForm.autonomus_community && providerForm.autonomus_community.length > 150) {
            return "La comunidad autonoma no debe exceder los 150 caracteres";
        }
        if (providerForm.province && providerForm.province.length > 150) {
            return "La provincia no debe exceder los 150 caracteres";
        }
        if (providerForm.surname && providerForm.surname.length > 36) {
            return "Los apellidos no deben exceder los 36 caracteres";
        }
        if (providerForm.cif && providerForm.cif.length > 9) {
            return "El cif no debe exceder los 9 caracteres";
        }
        if (providerForm.address && providerForm.address.length > 150) {
            return "La dirección no debe exceder los 150 caracteres";
        }
        if (providerForm.telephone_number && providerForm.telephone_number.length > 15) {
            return "El teléfono no debe exceder los 15 caracteres";
        }
        return null;
    };

    const createProvider = async (event) => {
        event.preventDefault();
        const validationError = validateForm();
        if (validationError) {
            setError(validationError);
            return;
        }
        try {
            setLoading(true);

            const result = await api().post('/providers/new', providerForm);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    alert("Proveedor creado correctamente");
                    navigate(`/providers/list`);
                    setError(null);
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '120%' }}>
            <Paper elevation={3} style={{ padding: 20, width: '40%' }}>
                <Typography component="h2" variant="h5">
                    Nuevo Proveedor
                </Typography>
                <br></br>
                <form onSubmit={createProvider}>
                    <Grid container spacing={2}>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Nombre"
                                name="name"
                                value={providerForm.name}
                                onChange={handleChange}
                                margin="normal"
                                inputProps={{ maxLength: 36 }}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Email"
                                name="email"
                                value={providerForm.email}
                                onChange={handleChange}
                                margin="normal"
                                inputProps={{ maxLength: 36 }}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="CIF"
                                name="cif"
                                value={providerForm.cif}
                                onChange={handleChange}
                                margin="normal"
                                inputProps={{ maxLength: 9 }}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Teléfono"
                                name="telephone_number"
                                value={providerForm.telephone_number}
                                onChange={handleChange}
                                margin="normal"
                                inputProps={{ maxLength: 15 }}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="País"
                                name="country"
                                value={providerForm.country}
                                onChange={handleChange}
                                margin="normal"
                                inputProps={{ maxLength: 36 }}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Comunidad autonoma"
                                name="autonomus_community"
                                value={providerForm.autonomus_community}
                                onChange={handleChange}
                                margin="normal"
                                inputProps={{ maxLength: 150 }}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Provincia"
                                name="province"
                                value={providerForm.province}
                                onChange={handleChange}
                                margin="normal"
                                inputProps={{ maxLength: 150 }}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Código postal"
                                name="cp"
                                value={providerForm.cp}
                                onChange={handleChange}
                                margin="normal"
                                inputProps={{ maxLength: 9 }}
                            />
                        </Grid>
                        <Grid item xs={10} md={12}>
                            <TextField
                                fullWidth
                                label="Dirección"
                                name="address"
                                value={providerForm.address}
                                onChange={handleChange}
                                margin="normal"
                                inputProps={{ maxLength: 150 }}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <Button type="submit" variant="contained" color="primary" disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : 'Crear'}
                            </Button>
                        </Box>
                    </Grid>
                </form>
                {error && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error}
                    </Typography>
                )}
            </Paper >
        </div>
    );
}

export default NewProvider;
